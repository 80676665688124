import { useNavigate } from 'react-router-dom'
import { Flex, HStack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { GetCampaignDto } from '~shared/dtos'

import { Highlight } from '../components/Highlight'

export const Campaign = ({
  campaign,
  query,
}: {
  campaign: GetCampaignDto
  query?: string
}) => {
  const { name, id, numberOfSubmissionLinks, lastUpdatedAt, numberOfFiles } =
    campaign
  const lastUpdatedDateTime =
    dayjs(lastUpdatedAt)?.format('DD/MM/YYYY hh:mm a') ?? '--'
  const navigate = useNavigate()

  return (
    <HStack
      align="center"
      _hover={{
        bgColor: 'interaction.main-subtle.default',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/admin/collections/${id}`)}
      py={'24px'}
      px="40px"
      textColor="base.content.strong"
      spacing="12px"
    >
      <Text textStyle="body-1" flex={3}>
        <Highlight text={name} query={query ?? ''} />
      </Text>
      <Text textStyle="body-1" flex={1}>
        {numberOfSubmissionLinks}
      </Text>
      <Text textStyle="body-1" flex={1}>
        {numberOfFiles}
      </Text>
      <Text flex={2}>{lastUpdatedDateTime}</Text>
    </HStack>
  )
}

export const CampaignHeader = () => {
  return (
    <HStack
      fontWeight="500"
      w="full"
      py="22px"
      px="40px"
      textColor={'interaction.sub.default'}
      spacing="12px"
    >
      <Text flex={3} textStyle={'subhead-2'}>
        Collection Name
      </Text>
      <Text flex={1} textStyle={'subhead-2'}>{`No. of links`}</Text>
      <Text flex={1} textStyle={'subhead-2'}>{`No. of files`}</Text>
      <Text flex={2} textStyle={'subhead-2'}>
        Last Updated
      </Text>
    </HStack>
  )
}
